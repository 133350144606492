import React from "react"
import { Link, graphql } from "gatsby"
import NoImage from "../images/no-image-thumbnail.png"
import NoImageWebp from "../images/no-image-thumbnail.webp"

import Layout from "../components/layout"
import Seo from "../components/seo"

export default function Category({ pageContext, location, data }) {
  const posts = data.allContentfulPost.edges
  const { currentPage, numPages, category } = pageContext
  // カテゴリータイトルを日本語化
  let categoryTitle = category
  if (categoryTitle === "camera") categoryTitle = "監視カメラ"
  if (categoryTitle === "recorder") categoryTitle = "レコーダー"
  if (categoryTitle === "transfer") categoryTitle = "画像伝送装置"
  if (categoryTitle === "thermo") categoryTitle = "非接触型測温計"
  if (categoryTitle === "miscs") categoryTitle = "周辺機器"
  if (categoryTitle === "news") categoryTitle = "お知らせ"
  if (categoryTitle === "blog") categoryTitle = "技術ブログ"
  if (categoryTitle === "software") categoryTitle = "ソフトウェア"
  if (categoryTitle === "case") categoryTitle = "事例紹介"

  return (
    <Layout location={location} title={categoryTitle}>
      <Seo title={categoryTitle} />
      <div className="breadcrumb">
        <Link to={"/"}> ホーム </Link>
        <span>/</span>
        <Link to={`/category/${category}`}>{categoryTitle}</Link>
      </div>
      <div className={category !== "news" ? "post-list" : "post-list-news"}>
        {/* 記事一覧の作成 */}
        {posts.map(post => {
          const title = post.node.title || post.node.slug
          const image = post.node.image
          return (
            <>
              <article
                className={
                  post.node.category !== "news"
                    ? "post-list-item post-list-item-product"
                    : "post-list-item-news"
                }
                itemScope
                itemType="http://schema.org/Article"
              >
                <Link
                  to={`/${post.node.category}/${post.node.slug}`}
                  itemProp="url"
                >
                  <header>
                    <Link
                      to={`/${post.node.category}/${post.node.slug}`}
                      itemProp="url"
                    >
                      {/* サムネ画像なし */}
                      {!image && post.node.category !== "news" && (
                        <picture>
                          <source type="image/webp" srcSet={NoImageWebp} />
                          <img src={NoImage} alt="post-cover" />
                        </picture>
                      )}
                      {/* サムネ画像あり */}
                      {image && post.node.category !== "news" && (
                        <picture>
                          <source
                            type="image/webp"
                            srcSet={image.gatsbyImageData.images.sources[0].srcSet}
                          />
                          <img
                            src={image.file.url}
                            className="post-image"
                            alt="post-cover"
                          ></img>
                        </picture>
                      )}
                    </Link>
                    {/* ニュース専用 見出し（H2） */}
                    <h2>
                      {post.node.category === "news" && (
                        <span className="post-list-item-news-date">
                          {post.node.publishDate}
                        </span>
                      )}
                      {post.node.category === "news" && (
                        <span className="post-list-item-news-icon">
                          お知らせ
                        </span>
                      )}
                      <br className="mobile-br" />
                      <br className="mobile-br" />
                      <Link
                        to={`/${post.node.category}/${post.node.slug}`}
                        itemProp="url"
                      >
                        <span itemProp="headline"> {title} </span>
                      </Link>
                    </h2>
                    {post.node.category === "blog" && (
                      <small> {post.node.publishDate} </small>
                    )}
                    {/* タグの色付け */}
                    <ul className="post-list-item-tag-ul">
                      {post.node.postTags &&
                        post.node.postTags.map(tag =>
                          // <li className="post-list-item-tags">{tag}</li>
                          tag === "販売終了" ? (
                            <li className="post-list-item-tags-end"> {tag} </li>
                          ) : (
                            <li className="post-list-item-tags">{tag}</li>
                          )
                        )}
                    </ul>
                  </header>
                </Link>
              </article>
            </>
          )
        })}
        {/* 記事一覧　端数の処理 */}
        {category !== "news" && posts.length % 3 === 1 && (
          <>
            <article className="post-list-item-product-null"></article>
            <article className="post-list-item-product-null"></article>
          </>
        )}
        {category !== "news" && posts.length % 3 === 2 && (
          <>
            <article className="post-list-item-product-null"></article>
          </>
        )}
      </div>
      {/* ページネーション */}
      <div className="pagination">
        {/* {
          Array(numPages).fill(0).map((_, i) => {
            return ( <div>hello</div> )
          })
        } */}
        {/* 総ページ数１ */}
        {numPages === 1 && <span className="pagination-current-page">1</span>}
        {/* 総ページ数２ */}
        {numPages === 2 && currentPage === 1 && (
          <>
            <span className="pagination-current-page">1</span>
            <Link to={`/category/${category}/${currentPage + 1}`}>
              <span className="pagination-page">2</span>
            </Link>
          </>
        )}
        {numPages === 2 && currentPage === 2 && (
          <>
            <Link to={`/category/${category}/`}>
              <span className="pagination-page">1</span>
            </Link>
            <span className="pagination-current-page">2</span>
          </>
        )}
        {/* 総ページ数３ */}
        {numPages >= 3 && (
          <>
            <Link to={`/category/${category}/`}>
              <span className="pagination-page-arrow">&lt;&lt;</span>
            </Link>
            {numPages >= 3 && currentPage === 1 && (
              <>
                <span className="pagination-current-page">{currentPage}</span>
                <Link to={`/category/${category}/${currentPage + 1}`}>
                  <span className="pagination-page">{currentPage + 1}</span>
                </Link>
                <Link to={`/category/${category}/${currentPage + 2}`}>
                  <span className="pagination-page">{currentPage + 2}</span>
                </Link>
              </>
            )}
            {numPages >= 3 && currentPage !== 1 && currentPage !== numPages && (
              <>
                {currentPage - 1 === 1 && (
                  <Link to={`/category/${category}/`}>
                    <span className="pagination-page">{currentPage - 1}</span>
                  </Link>
                )}
                {currentPage - 1 !== 1 && (
                  <Link to={`/category/${category}/${currentPage - 1}`}>
                    <span className="pagination-page">{currentPage - 1}</span>
                  </Link>
                )}
                <span className="pagination-current-page">{currentPage}</span>
                <Link to={`/category/${category}/${currentPage + 1}`}>
                  <span className="pagination-page">{currentPage + 1}</span>
                </Link>
              </>
            )}

            {numPages >= 3 && currentPage === numPages && (
              <>
                {currentPage - 2 === 1 && (
                  <Link to={`/category/${category}/`}>
                    <span className="pagination-page">{currentPage - 2}</span>
                  </Link>
                )}
                {currentPage - 2 !== 1 && (
                  <Link to={`/category/${category}/${currentPage - 2}`}>
                    <span className="pagination-page">{currentPage - 2}</span>
                  </Link>
                )}
                <Link to={`/category/${category}/${currentPage - 1}`}>
                  <span className="pagination-page">{currentPage - 1}</span>
                </Link>
                <span className="pagination-current-page">{currentPage}</span>
              </>
            )}
            <Link to={`/category/${category}/${numPages}`}>
              <span className="pagination-page-arrow">&gt;&gt;</span>
            </Link>
          </>
        )}

        <br />
        <br />
        {`    ${currentPage} / ${numPages}ページ    `}
      </div>
    </Layout>
  )
}

export const blogQuery = graphql`
  query($category: String, $skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulPost(
      filter: { category: { eq: $category } }
      sort: { fields: publishDate, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          title
          image {
            gatsbyImageData(quality: 100, formats: WEBP)
            file {
              url
            }
          }
          slug
          category
          publishDate(locale: "ja-JP", formatString: "YYYY/MM/DD")
          postTags
        }
      }
    }
  }
`
